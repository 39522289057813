<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				帮助详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li class="Sel" v-if="Help.Id != 'add'">
					<span class="Sel">帮助ID</span>
					<em class="Sel">
						{{Help.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*标题</span>
					<em  class="Sel">
						<el-input v-model="Help.Title" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*分类</span>
					<em class="Sel">
						<el-select placeholder="设置分类" v-model="Help.CategoryId">
							<template v-for="cat in CategoryList">
								<el-option :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
							</template>
					    </el-select>
					</em>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">*详情</span>
					<em  class="Sel" style="display: block;min-height: 400px;max-width: 800px;">
						<HelpContent v-if="ShowContent" :content="Content" @UpHelpContent="UpHelpContent"></HelpContent>
					</em>
				</li>
				
				
				<li class="Sel" v-if="Help.Id != 'add'">
					<span class="Sel">状态</span>
					<em class="Sel">
						<el-select placeholder="设置状态" v-model="Help.Status">
							<el-option label="展示中" :value="50"></el-option>
							<el-option label="不展示" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">排序权重</span>
					<em class="Sel">
						<el-input-number style="width: 100px;" :min="1" :max="9999" :controls="false" v-model="Help.Order"></el-input-number>
					</em>
					<i>权重值越大排名越靠前</i>
				</li>
				
				<li v-if="Help.Id != 'add'" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{Help.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Help.Id != 'add'" class="Sel">
					<span class="Sel">最后修改时间</span>
					<em class="Sel">
						{{Help.UpdatedAt}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel"></span>
					<em class="Sel">
						<el-button @click="UpdateHelp()" type="warning">{{Help.Id == 'add' ? '添加':'修改'}}</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Select,Option,Dialog,Upload,InputNumber} from 'element-ui'
	import VueQuillEditor from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import HelpContent from '@/components/help/HelpContent.vue'
	Vue.use(VueQuillEditor)
	export default {
	  name: 'HelpInfo',
	  props: {
	  },
	  data() {
	      return {
			  Help:{
				  Id:'',
				  Title:'',
				  SubTitle:'',
				  Order:0,
				  Platform:'',
				  CategoryId:'',
				  Detail:'',
				  Thumb:'',
				  CreatedAt:'',
				  UpdatedAt:'',
				  Status:50,
			  },
			  Content:'',
			  ShowContent:false,
			  CategoryList:[],
			  editorOption:{},
			  uploadModel:'single',//multiple 多个
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
		HelpContent
	  },
	  created() {
		this.GetCategoryList()
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少ID，页面无法工作')
			return
		}
		this.Help.Id = this.$route.params.Id
		if(this.Help.Id != 'add'){
			this.GetHelp(this.$route.params.Id)
			return
		}
		this.ShowContent = true
	  },
	  methods:{
		  UpHelpContent(_val){
		  	this.Content = _val
		  },
		  getFilesChange(file, fileList) {
			  this.uploadModel = 'multiple'
				this.getBase64(file.raw).then(res => {
					this.uploadPic(res)
				});
		  },
		    GetHelp(_id){
		  		let data = {Service:'Help',Class: 'Help',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Help = res.Data
					this.Content = this.Help.Detail
					this.ShowContent = true
		  		})
		    },  
			UpdateHelp(){
				let data = {
					Service:'Help',
					Class: 'Help',
					Action: 'Update',
					Id:this.Help.Id,
					Status:this.Help.Status,
					Title:this.Help.Title,
					Order:parseInt(this.Help.Order),
					CategoryId:this.Help.CategoryId,
					Detail:this.Content
				}
				if(this.Help.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0 && this.Help.Id == 'add'){
						this.$Jump('/do/help/list')
					}else if(res.ErrorId == 0){
						this.GetHelp(this.Help.Id)
					}
				})
			},
			GetCategoryList(){
					let data = {
						Service:'Help',
						Class: 'HelpCategory',
						Action: 'List',
						Page:1,
						PageSize:20,
					}
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						this.CategoryList = res.Data.HelpCategoryList
						
					})
					.catch(function (response) {
						this.$message("网络请求错误")
					})
			}
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 80px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i.Sel{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
</style>
